import React from "react";
import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { ServiceFields } from "./ServiceFields";
import { ServiceFormSpec } from "../applicationFormSpec";
import { useTranslatedField, useTranslations } from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { CheckboxFormField } from "../../Common/Fields";
import { CFIService } from "../cfiFormResult";

export interface ServiceEditorProps {}

export function ServiceEditor({}: ServiceEditorProps) {
  const { t, iu } = useTranslations("service");
  const { t: tCommon, iu: iuCommon } = useTranslations("common");

  const providingDocuments = useTranslatedField(
    (t) => t.service.hasDocuments,
    " ",
  );

  return (
    <FieldArray<Partial<CFIService>>
      name="services"
      render={({ fields }) => (
        <Stack divider={<Divider />} gap={4}>
          {fields.map((name, index) => (
            <Stack direction="row" gap={1}>
              <Box flex={1}>
                <ServiceFields name={name} index={index} />
              </Box>
              <Button
                variant="outlined"
                sx={{ minWidth: 0 }}
                onClick={() => fields.remove(index)}
                color="error"
                disabled={fields.length == 1}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </Stack>
          ))}

          <CheckboxFormField
            name="providingDocuments"
            label={providingDocuments}
            mustBeTrue
            showSpace
          />

          <FormSpy
            subscription={{ valid: true, errors: true }}
            render={({ valid, errors, form: { submit, mutators } }) => (
              <Stack direction="row" gap={2}>
                <Button
                  onClick={() => {
                    console.log(errors);
                    errors?.services !== undefined
                      ? submit()
                      : fields.push({ frequency: 1 });
                  }}
                  startIcon={<AddIcon />}
                  size="large"
                  variant="outlined"
                  sx={{ flex: 1 }}
                >
                  {appendWithoutHtml(t, iu, (t) => t.addService, " / ")}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  //A sneaky hack: If someone clicks Next with invalid fields, we want to show the validation errors
                  // for those fields. The easiest way to do that is to just submit the form, but we don't want them
                  // to submit the form when the fields are actually valid, so we only submit IF the fields are
                  // invalid. Otherwise, we progress like normal
                  onClick={() =>
                    errors?.services !== undefined
                      ? submit()
                      : mutators.finishedAddingServices()
                  }
                >
                  {appendWithoutHtml(tCommon, iuCommon, (t) => t.next, " / ")}
                </Button>
              </Stack>
            )}
          />
        </Stack>
      )}
    />
  );
}
