import React, { useEffect } from "react";
import { YesNoFormField } from "../../Common/Fields";
import { useField, useForm } from "react-final-form";

export interface OverTenChildrenPromptProps {}

export function OverTenChildrenPrompt({}: OverTenChildrenPromptProps) {
  return (
    <YesNoFormField
      name="overTenChildren"
      label={(t) => t.children.groupHasMoreThanTenChildren}
      showSpace
    />
  );
}
