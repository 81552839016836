import { DeepPartial } from "../common";
import React from "react";
import { FormSegmentProps } from "./FormSegment";

const __DEBUG_SHOW_ALL_SEGMENTS = false;

export interface FormNode<FormResult, Key extends string>
  extends Omit<FormSegmentProps, "in" | "children"> {
  id: Key;
  element: React.ReactNode;
  transitions?: FormNodeTransition<FormResult, Key>[];
}

export interface FormNodeTransition<FormResult, Key extends string> {
  to: Key;
  on: (item: DeepPartial<FormResult>) => boolean | undefined;
}

//From a list of form nodes and the current state of the form, builds a sequence
// of form segments to display to the user
export function buildFormFlow<FormResult, Key extends string>(
  formNodes: FormNode<FormResult, Key>[],
  state: FormResult
) {
  if (__DEBUG_SHOW_ALL_SEGMENTS) {
    return formNodes;
  }

  //We're gonna be manipulating the list of nodes so we slice the array into
  // a copy which we can do whatever we want with.
  const nodePool = formNodes.slice();

  //We also set our starting node to just be the first one on the list
  let renderedNodes: FormNode<FormResult, Key>[] = [formNodes[0]];
  let nodeAdded = true;

  while (nodeAdded) {
    //Based on the current state and segment, we fetch the ID of the next
    // node in the sequence
    const targetId = findTargetNodeId(
      renderedNodes[renderedNodes.length - 1],
      state
    );

    //In our node pool, we try and find the index of that node.
    const nodeIndex = nodePool.findIndex((n) => n.id === targetId);

    //If we found a node, we explicitly remove it from the node pool and add it to our
    // list of rendered segments. By removing it from the pool, we prevent infinite loops
    if (nodeIndex != -1) {
      renderedNodes = renderedNodes.concat(nodePool.splice(nodeIndex, 1));
    } else {
      nodeAdded = false;
    }
  }

  return renderedNodes;
}

//Given a current node, find the next node in the sequence based on the current state
function findTargetNodeId<FormResult, Key extends string>(
  currentNode: FormNode<FormResult, Key>,
  state: FormResult
) {
  if (!currentNode.transitions) {
    return undefined;
  }

  //We check all the transitions in our node until we find one whose condition is met
  for (let j = 0; j < currentNode.transitions?.length; j++) {
    if (currentNode.transitions[j].on(state as DeepPartial<FormResult>)) {
      return currentNode.transitions[j].to;
    }
  }
}
