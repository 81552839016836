import React, { useState } from "react";
import { Form } from "react-final-form";
import { Box } from "@mui/material";
import {
  CFIChild,
  CFIFormResult,
  CFIService,
  FormResult,
} from "./cfiFormResult";
import arrayMutators from "final-form-arrays";
import { formApi, translationApi } from "../Common/API";
import {
  TranslationProvider,
  useAllTranslations,
  useFullTranslationObject,
  useTranslatedField,
} from "./TranslationProvider";
import { useSelectedLanguage } from "../SelectedLanguageProvider";
import { useNavigate } from "react-router-dom";
import { ErrorNotification, useAsyncData } from "@torqit/crew-2ls";
import { FormContentWrapper } from "./FormContentWrapper";

export interface FormProps {}

const initialValues: Partial<CFIFormResult> = {
  children: [{ id: 1 } as CFIChild],
  services: [{ frequency: 1 } as CFIService],
};

export function ApplicationForm({}: FormProps) {
  const { language } = useSelectedLanguage();

  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const submit = async (result: FormResult) => {
    try {
      await formApi.submit(result);
      navigate("/success");
    } catch {
      setShowError(true);
    }
  };

  const translations = useFullTranslationObject();

  const tooManyDocumentsWarning = useTranslatedField(
    (t) => t.conclusion.documents.tooManyDocumentsWarning,
    " / ",
  );

  return (
    <Box pb={75}>
      <Form<FormResult>
        onSubmit={submit}
        subscription={{}}
        validate={(values) => {
          let documentCount = 0;

          if (values.documents) {
            for (const [key, value] of Object.entries(values.documents)) {
              documentCount += value.length;
            }
          }

          if (documentCount > 20) {
            return { documentLimit: tooManyDocumentsWarning };
          }
        }}
        mutators={{
          finishedAddingChildren: (args, state, tools) =>
            tools.changeValue(state, "finishedAddingChildren", () => true),
          finishedAddingServices: (args, state, tools) =>
            tools.changeValue(state, "finishedAddingServices", () => true),
          finishedAddingDocuments: (args, state, tools) =>
            tools.changeValue(state, "finishedAddingDocuments", () => true),

          setOneChild: (args, state, tools) =>
            tools.changeValue(state, "children", () => [{ id: 1 } as CFIChild]),
          setOverTenChildren: (args, state, tools) =>
            tools.changeValue(state, "children", () =>
              //Generate 11 children in total, each with unique IDs
              Array.from(Array(11).keys()).map(
                (id) => ({ id: id }) as CFIChild,
              ),
            ),

          ...arrayMutators,
        }}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormContentWrapper translations={translations} />
          </form>
        )}
      />
      <ErrorNotification
        open={showError}
        onClose={() => setShowError(false)}
        message="An error occurred while submitting the form"
      />
    </Box>
  );
}
