import React, { useEffect } from "react";
import { buildApplicationFlow } from "./applicationFormFlow";
import { FormFlow } from "../Common/FormFlow";
import { ApplicationFormTranslations } from "../Common/API/translationApi";
import { useSelectedLanguage } from "../SelectedLanguageProvider";
import { Box, Card, CardContent, Skeleton, Stack } from "@mui/material";
import { useField, useForm } from "react-final-form";
import { useFieldValue } from "../Common/useFieldValue";

export interface FormContentWrapperProps {
  translations?: ApplicationFormTranslations;
}

//This component solely exists for development convenience; by wrapping the form flow in
// component that's completely separate from the root Form, every time the form flow
// is updated, it no longer triggers a re-render on the entire form, causing you to lose
// your values and fill in everything all over again. By making it a separate component
// it only needs to re-render the form segments, which means you now keep all your
// values as you make updates.

//Update: It now also exists to host a useEffect() that should be form wide

export function FormContentWrapper({ translations }: FormContentWrapperProps) {
  const { language } = useSelectedLanguage();

  const t = language === "en" ? translations?.en : translations?.fr;
  const iu = translations?.iu;

  const overTenChildren = useFieldValue<"Yes" | "No">("overTenChildren");
  const { mutators } = useForm();

  //Previously, this useEffect() was ran in the OverTenChildrenPrompt component, but there's
  // a problem: If the user goes back an edits fields before the prompt, the segment might
  // collapse because the form temporarily doesn't flow that way, remounts once the fields
  // are filled again and then re-runs the effect on mount. As a result, any children you
  // had filled out all get overwritten, which leaves them dangling in the services too if
  // you filled those out as well. Instead, we move it all the way to the tippy top of the
  // form, so we don't have to worry about mounting/unmounting messing everything up.
  useEffect(() => {
    if (overTenChildren === "Yes") {
      mutators.setOverTenChildren();
    } else {
      mutators.setOneChild();
    }
  }, [overTenChildren, mutators]);

  if (!t || !iu) {
    return (
      <Card>
        <CardContent>
          <Skeleton height={40} />
          <Box pl={5}>
            <Skeleton height={32} />
            <Stack direction="row" gap={3}>
              <Skeleton height={32} width={100} />
              <Skeleton height={32} width={100} />
            </Stack>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return <FormFlow flow={buildApplicationFlow(t, iu)} />;
}
