import React from "react";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { DateFormField, TextFormField } from "../../Common/Fields";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslatedField, useTranslations } from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { IUnderstandCheckbox } from "../../Common/Fields/IUnderstandCheckbox";
import { FormSpy, useField, useFormState } from "react-final-form";
import { RequestSummary } from "./RequestSummary";
import { CFIFormResult, FormResult } from "../cfiFormResult";
import { DeepPartial } from "../../Common/common";
import ConstructionIcon from "@mui/icons-material/Construction";
import { ApplicationType } from "../Elimination/SelectApplicationType";
import { PaymentRequestSummary } from "../Payment/PaymentRequestSummary/PaymentRequestSummary";

export interface ConclusionProps {}

export function Conclusion({}: ConclusionProps) {
  const { t, iu } = useTranslations("conclusion");
  const { t: tCommon, iu: iuCommon } = useTranslations("common");

  const submitLabel = useTranslatedField((t) => t.conclusion.submit);

  const { submitting } = useFormState({ subscription: { submitting: true } });

  const {
    input: { value: applicationType },
  } = useField<ApplicationType>("applicationType");

  return (
    <>
      <Box mb={2}>
        <FormSpy<FormResult>
          subscription={{ values: true }}
          render={({ values }) =>
            values.applicationType === "payment" ? (
              <PaymentRequestSummary
                clientType={values.clientType}
                services={values.services}
                children={values.children}
              />
            ) : (
              <RequestSummary
                applicationType={values.applicationType}
                services={values.services}
                children={values.children}
              />
            )
          }
        />
      </Box>
      <ConvertToMui getField={(t) => t.conclusion.conclusion} />
      <Box mb={2}>
        <IUnderstandCheckbox name="finalConsent" />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <DateFormField
            name="date"
            label={appendWithoutHtml(t, iu, (t) => t.date, " / ")}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="signature"
            label={appendWithoutHtml(t, iu, (t) => t.signature, " / ")}
            required
          />
        </Grid>
      </Grid>
      <Box textAlign="center" mt={3}>
        {!submitting && (
          <Stack justifyContent="center" gap={2} alignItems="center">
            <FormSpy
              subscription={{ valid: true, errors: true }}
              render={({ errors }) => (
                <>
                  {errors?.documentLimit && (
                    <Typography color="error" variant="body2">
                      {errors.documentLimit}
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ px: 4, py: 2, fontSize: 20 }}
                    startIcon={<CheckIcon style={{ fontSize: 32 }} />}
                    type="submit"
                    disabled={errors?.documentLimit != null}
                  >
                    {submitLabel}
                  </Button>
                </>
              )}
            />
          </Stack>
        )}
        {submitting && (
          <Box p={2}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
    </>
  );
}
