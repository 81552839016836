import React, { useMemo } from "react";
import { ExtendedTableColumn } from "@torqit/crew-2ls";
import { Box, Stack, Typography } from "@mui/material";
import { CFIChild, CFIService } from "../../cfiFormResult";
import { ApplicationType } from "../../Elimination/SelectApplicationType";
import { useTranslatedField, useTranslations } from "../../TranslationProvider";
import { appendWithoutHtml } from "../../../Common/appendInuktitut";
import dayjs from "dayjs";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  useGetServiceLabel,
  useServiceOptions,
} from "../../ServiceOptionsProvider";
import { useFieldValue } from "../../../Common/useFieldValue";

const currencyFormat = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
});

export function calculateTotal(s?: Partial<CFIService>) {
  if (!s?.cost || !s?.frequency) {
    return 0;
  }

  return s.cost * s.frequency;
}

export function useRequestSummaryColumns(
  children?: CFIChild[],
  applicationType?: ApplicationType,
) {
  const { t, iu } = useTranslations("conclusion");

  const getServiceLabel = useGetServiceLabel();
  const startDate = useTranslatedField((t) => t.service.startDate, " / ");
  const endDate = useTranslatedField((t) => t.service.endDate, " / ");
  const serviceType = useTranslatedField(
    (t) => t.conclusion.requestSummary.serviceType,
    " / ",
  );

  const dateColumns: ExtendedTableColumn<Partial<CFIService>>[] =
    applicationType === "personal"
      ? [
          {
            key: "startDate",
            title: startDate,
            render: (s) => dayjs(s.startDate).format("LL"),
          },
          {
            key: "endDate",
            title: endDate,
            render: (s) =>
              s.endDate ? (
                dayjs(s.endDate).format("LL")
              ) : (
                <RemoveIcon sx={{ color: "secondary.dark" }} />
              ),
          },
        ]
      : [];

  const columns: ExtendedTableColumn<Partial<CFIService>>[] = [
    {
      key: "service",
      title: appendWithoutHtml(t, iu, (t) => t.requestSummary.service, " / "),
      render: (s) => {
        if (!s.service) {
          return;
        }

        if (s.service.startsWith("Other")) {
          return s.customService;
        } else {
          return getServiceLabel(s.service);
        }
      },
    },
    {
      key: "serviceType",
      title: serviceType,
      render: (s) => s.serviceType,
    },
    ...dateColumns,
    {
      key: "cost",
      title: appendWithoutHtml(t, iu, (t) => t.requestSummary.cost, " / "),
      render: (s) => currencyFormat.format(Number(s.cost)),
      footer: () => (
        // By placing our labels into an absolute box within a relative box,
        // they no longer need to fit within the cell, so they won't blow up
        // the column size. Nothing else needs to display on the footer,
        // so it'll never overlap any other content. A little hacky, but
        // it gets the job done
        <Box position="relative" height={50} width="100%">
          <Box position="absolute" textAlign="right" width={500} right={0}>
            <Typography fontWeight="bold" fontSize={14}>
              {t.requestSummary.grandTotal}:
            </Typography>
            <Typography fontWeight="bold" fontSize={14}>
              {iu.requestSummary.grandTotal}:
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      key: "total",
      title: appendWithoutHtml(t, iu, (t) => t.requestSummary.total, " / "),
      render: (s) => currencyFormat.format(calculateTotal(s)),
      footer: (data) => (
        <Box pr={3}>
          <Typography fontWeight="bold">
            {currencyFormat.format(
              data?.reduce(
                (sum, current) => (sum += calculateTotal(current)),
                0,
              ) ?? 0,
            )}
          </Typography>
        </Box>
      ),
    },
  ];

  if (applicationType === "personal") {
    const childColumns: ExtendedTableColumn<CFIService>[] = [
      {
        key: "children",
        title: appendWithoutHtml(
          t,
          iu,
          (t) => t.requestSummary.children,
          " / ",
        ),
        render: (s) => {
          const serviceChildren = children?.filter((c) =>
            s.children?.includes(c.id),
          );

          return (
            <Stack>
              {serviceChildren?.map((c) => (
                <Box key={c.id}>
                  {c?.firstName} {c?.lastName} (
                  {c?.dateOfBirth
                    ? dayjs(c.dateOfBirth).format("ll")
                    : "Unknown"}
                  )
                </Box>
              ))}
            </Stack>
          );
        },
      },
    ];

    return childColumns.concat(columns);
  }

  return columns;
}
