import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Field, useField } from "react-final-form";
import { FileDropper } from "../FileDropper";
import { FieldWrapper, FieldWrapperProps } from "./FieldWrapper";
import ClearIcon from "@mui/icons-material/Clear";
import { ApplicationFormSpec } from "../../ApplicationForm/applicationFormSpec";
import {
  useAllTranslations,
  useTranslatedField,
} from "../../ApplicationForm/TranslationProvider";
import { stripHtml } from "string-strip-html";

export interface FileFormFieldProps
  extends Omit<FieldWrapperProps, "children"> {
  name: string;
  label?: (t: ApplicationFormSpec) => string | undefined;
  multiple?: boolean;
  required?: boolean;
}

export const FileFormField: React.FC<FileFormFieldProps> = ({
  name,
  label,
  multiple,
  required,
  ...wrapperProps
}) => {
  const { t, iu } = useAllTranslations();

  const thisFieldIsRequired = useTranslatedField(
    (t) => t.common.thisFieldIsRequired,
    " / "
  );

  const { input, meta } = useField(name, {
    type: "file",
    validate: (v) => (required && v == null ? thisFieldIsRequired : undefined),
  });

  const hasError = meta.touched && meta.error != null;

  return (
    <FieldWrapper {...wrapperProps}>
      <FormControl fullWidth>
        {label && (
          <FormLabel error={hasError}>
            <Typography variant="h5">
              {stripHtml(label(t) ?? "").result} {required && "*"}
            </Typography>
            <Typography variant="h5">
              {stripHtml(label(iu) ?? "").result} {required && "*"}
            </Typography>
          </FormLabel>
        )}
        <Stack direction="row" gap={2}>
          <Box flex={1}>
            <FileDropper
              onChange={(files) => {
                if (multiple) {
                  input.onChange(
                    (input.value === null || input.value === ""
                      ? []
                      : input.value
                    ).concat(files)
                  );
                } else {
                  input.onChange(files);
                }
              }}
              files={input.value}
              multiple={multiple}
            />
          </Box>
          {input.value != null && input.value.length > 0 && (
            <Button
              variant="outlined"
              onClick={() => input.onChange([])}
              sx={{ minWidth: 0 }}
            >
              <ClearIcon />
            </Button>
          )}
        </Stack>
        {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
      </FormControl>
    </FieldWrapper>
  );
};
